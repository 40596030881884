import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./components.css";
import { Loader } from "../components/loader";
import { Link, useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import logo_white from "../images/LOGO_V1.png";
import logo_black from "../images/logo-black.png";
import dispute from "../images/parties.png";
import Swal from "sweetalert2";

export const Aside = ({
    isDarkModeActive,
    setIsDarkModeActive
  }) => {
  const location: any = useLocation();
  const [createDispute, setcreateDispute] = useState(false);
  const { publicKey } = useWallet();
  const [disputePorjectId, setdisputePorjectId] = useState("");
  const [disputeDiscription, setdisputeDiscription] = useState("");
  const [totalContract, settotalContract] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activemenu, setactivemenu] = useState("dashboard");

  function active_menu(val) {
    setactivemenu(val);
  }

  let handleSubmit = async (e) => {
    setIsLoading(true);
    console.log("dd");
    e.preventDefault();
    let iserror = false;
    if (disputePorjectId.length === 0) {
      iserror = true;
    }
    if (disputeDiscription.length === 0) {
      iserror = true;
    }
    if (!iserror) {
      try {
        // let res = await fetch(process.env.REACT_APP_API_URL+"createDispute", {
        let res = await fetch(process.env.REACT_APP_API_URL + "createDispute", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            disputeDescription: disputeDiscription,
            mileStoneId: "string",
            projectId: disputePorjectId,
            walletAdress: publicKey.toString(),
          }),
        });
        let resJson = await res.text();
        console.log(resJson);
        setIsLoading(false);
        setcreateDispute(false);
        if (res.status === 200) {
          Swal.fire({
            title: "Your Dispute Has Submitted Successfully. ",
            text: "Our team will get back to you shortly",
            confirmButtonText: "Okey",
          });
        } else {
          console.log("failure");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "Some Details Are Empty",
        text: "Please check and submit again.",
        confirmButtonText: "Okey",
      });
    }
  };

  useEffect(() => {
    if (location.state && location.state.from == "my-project") {
      setactivemenu("My Projects");
    } else if (location.state && location.state.from == "dashboard") {
      setactivemenu("Dashboard");
    } else if (location.state && location.state.from == "transactions") {
      setactivemenu("Transactions");
    } else if (location.state && location.state.from == "chat") {
      setactivemenu("Chat");
    }
  }, [location.state]);

  const handleClose = () => setcreateDispute(false);

  function open_create_dispute_modal() {
    setcreateDispute(true);
  }

  // const getAllContract = async () => {
  //     try {
  //         console.log("aside call started")
  //         const response = await fetch(process.env.REACT_APP_API_URL+'getAllContracts/' + publicKey.toString(), { mode: 'cors' });
  //         const data = await response.json();
  //         console.log(data['buyerContracts'] + data['sellerContracts'])
  //         settotalContract([...data['buyerContracts'], ...data['sellerContracts']])
  //         console.log("aside call done")
  //     }
  //     catch (e) {
  //         console.log(e)
  //     }
  // }

  useEffect(() => {
    if (publicKey) {
      localStorage.setItem("p_key", publicKey.toString());
      //  getAllContract();
    }
  }, [publicKey]);

  const hideMenu = () => {
    document.documentElement.classList.remove('show-mobile-menu');
};


  // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  return (
    <>
      {isLoading && <Loader />}
      {/* <Modal show={createDispute} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Create Dispute</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} id="kt_modal_profile_update_form" className="form" action="#">

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">


                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">Choose The Project</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Select the contract type."></i>
                        </label>


                        <select
                            className="form-select form-select-solid"

                            name="created_for"
                            onChange={(e) => setdisputePorjectId(e.target.value)} >
                                <option>Choose the Project</option>
                            {totalContract.map(contract =>
                                <option key={contract.projectId} value={contract.projectId}>{contract.projectTitle}</option>
                            )}

                        </select>

                    </div>

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                        <label className="required fs-5 fw-bold mb-2">Dispute Description</label>

                        <textarea
                            className="form-control form-control-solid"
                            rows={3} name="description"
                            placeholder="Describe your dispute"
                            onChange={(e) => setdisputeDiscription(e.target.value)}></textarea>
                    </div>

                    <div className="text-center">
                        <button type="reset" className="btn btn-light me-3" onClick={handleClose}>Cancel</button>
                        <button onClick={handleSubmit} type="submit" className="btn btn-primary">
                            <span className="indicator-label">Submit Dispute</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>

                </form>
            </Modal.Body>


        </Modal> */}
      <div
        id="kt_aside"
        className="aside"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="auto"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div
          className="aside-logo d-lg-flex flex-column align-items-center flex-column-auto py-8"
          id="kt_aside_logo"
        >
          <a href="/">

            <img alt="Logo" src={isDarkModeActive ? logo_white : logo_black} className="h-55px" />
          </a>

          <div className="close-menu d-lg-none"  onClick={() => hideMenu()}>
          <i className="bi bi-x-circle"></i>
                </div>
        </div>
        <div
          className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0"
          id="kt_aside_nav"
        >
          <div
            id="kt_aside_menu"
            className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6"
            data-kt-menu="true"
          >
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "Dashboard"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/" }}
                state={{ from: "dashboard" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi bi-bar-chart-line fs-2"></i>
                </span>

                <span className="menu-title">Dashboard</span>
              </Link>
            </div>
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "My Projects"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/my-project" }}
                state={{ from: "my-project" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi bi-file-text fs-2"></i>
                </span>

                <span className="menu-title">Projects</span>
              </Link>
            </div>
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "Transactions"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/transactions" }}
                state={{ from: "transactions" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi bi-window-stack fs-2"></i>
                </span>

                <span className="menu-title">Transactions</span>
              </Link>
            </div>
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "Chat"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/chat" }}
                state={{ from: "chat" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi-chat-right-dots fs-2"></i>
                </span>

                <span className="menu-title">Chat</span>
              </Link>
            </div>
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "Resolution Management"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/resolution-management" }}
                state={{ from: "resolution-management" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi bi-intersect fs-2"></i>
                </span>

                <span className="menu-title">Dispute</span>
              </Link>
            </div>

            <div
              data-kt-menu-trigger="click"
              data-kt-menu-placement="right-start"
              className={
                activemenu == "Support Management"
                  ? "menu-item here show py-3"
                  : "menu-item here py-3"
              }
            >
              <Link
                className="menu-link"
                to={{ pathname: "/support-ticket" }}
                state={{ from: "support-ticket" }}
                onClick={() => hideMenu()}
              >
                <span className="menu-icon me-0">
                  <i className="bi bi-question-circle fs-2"></i>
                </span>

                <span className="menu-title">Support Ticket</span>
              </Link>
            </div>

          </div>
        </div>

      </div>
    </>
  );
};
