import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import "./project-details.css";
import { Loader } from "../components/loader";
import { useNavigate, Link } from "react-router-dom";
import "./dashboard.css";
import completed_img from "../images/complete.png";
import completed_dark_img from "../images/complete-dark.png";
import inprogress_img from "../images/arrows.png";
import inprogress_dark_img from "../images/arrows-dark.png";
import created_img from "../images/add.png";
import created_dark_img from "../images/add-dark.png";
import draft_img from "../images/draft.png";
import draft_dark_img from "../images/draft-dark.png";
import others_img from "../images/more.png";
import others_dark_img from "../images/more-dark.png";
import submitted_img from "../images/submit.png";
import change_request_img from "../images/change.png";
import withdraw_img from "../images/withdrawal.png";
import dispute_img from "../images/dispute.png";
import dispute_dark_img from "../images/dispute-dark.png";
import reject_img from "../images/reject.png";
import earn_img from "../images/earn.png";
import earn_dark_img from "../images/earn-dark.png";
import spend_img from "../images/hand.png";
import spend_dark_img from "../images/hand-dark.png";

export const Dashboard = ({ isDarkModeActive, setIsDarkModeActive }) => {
  // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setdashboard] = useState({
    activeMilestones: [
      {
        mileStoneId: "",
        mileStoneTitle: "",
        projectId: "",
        projectTitle: "",
      },
    ],
    totalEarnings: {
      inEscrow: 0,
      pending: 0,
      totalEarned: 0,
      totalEstimated: 0,
    },
    totalMilestones: {
      completed: 0,
      inProgress: 0,
      total: 0,
    },
    totalProjects: {
      completed: 0,
      inProgress: 0,
      total: 0,
    },
    totalSpends: {
      inEscrow: 0,
      pending: 0,
      totalEstimated: 0,
      totalSpend: 0,
    },
  });
  const { publicKey } = useWallet();
  const get_project = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getDashboardData/" +
          publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      data["totalEarnings"]["totalEstimated"] = parseFloat(
        data["totalEarnings"]["totalEstimated"]
      ).toFixed(4);
      data["totalEarnings"]["totalEarned"] = parseFloat(
        data["totalEarnings"]["totalEarned"]
      ).toFixed(4);
      data["totalEarnings"]["inEscrow"] = parseFloat(
        data["totalEarnings"]["inEscrow"]
      ).toFixed(4);
      data["totalEarnings"]["pending"] = parseFloat(
        data["totalEarnings"]["pending"]
      ).toFixed(4);

      data["totalSpends"]["totalEstimated"] = parseFloat(
        data["totalSpends"]["totalEstimated"]
      ).toFixed(4);
      data["totalSpends"]["totalSpend"] = parseFloat(
        data["totalSpends"]["totalSpend"]
      ).toFixed(4);
      data["totalSpends"]["inEscrow"] = parseFloat(
        data["totalSpends"]["inEscrow"]
      ).toFixed(4);
      data["totalSpends"]["pending"] = parseFloat(
        data["totalSpends"]["pending"]
      ).toFixed(4);


      data["recentProjects"] = data["recentProjects"].slice(0, 6);
      setdashboard(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  let call_project_details = (param) => (e) => {
    console.log(param);
    localStorage.setItem("project_id", param);
    // window.location.href = '/project-milestone/'+param;
    navigate("/project-milestone/" + param);
  };

  useEffect(() => {
    setIsLoading(true);
    if (publicKey) {
      get_project();
      //   setInterval(init, 1000);
    }
  }, [publicKey]);

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
          <div className="row g-5 g-xl-8">
            <div className="col-xl-4">
              <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                  <div className="px-9 card-rounded h-120px w-100 total-project-card">
                    <div className="d-flex text-center flex-column text-white pt-8">
                      <span className="fw-bold fs-1">Total Projects</span>
                      <span className="fw-bold fs-2x pt-1">
                        {dashboard["totalProjects"]["total"]}
                      </span>
                    </div>
                  </div>

                  <div className="card-inner-body mx-9 pt-9 position-relative z-index-1">
                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={isDarkModeActive ? draft_dark_img : draft_img}
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800 fw-bolder"
                          >
                            In Draft
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalProjects"]["inDraft"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive ? created_dark_img : created_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Created
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalProjects"]["created"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive
                              ? inprogress_dark_img
                              : inprogress_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            In Progress
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalProjects"]["inProgress"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive
                              ? completed_dark_img
                              : completed_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Completed
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalProjects"]["completed"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={isDarkModeActive ? others_dark_img : others_img}
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Others
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalProjects"]["others"]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                  <div className="px-9 card-rounded h-120px w-100 total-project-card">
                    <div className="d-flex text-center flex-column text-white pt-8">
                      <span className="fw-bold fs-1">Total Milestone</span>
                      <span className="fw-bold fs-2x pt-1">
                        {dashboard["totalMilestones"]["total"]}
                      </span>
                    </div>
                  </div>

                  <div className="card-inner-body mx-9 mb-9 pt-9 position-relative z-index-1">
                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive ? created_dark_img : created_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Created
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalMilestones"]["created"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive
                              ? inprogress_dark_img
                              : inprogress_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            In Progress
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalMilestones"]["inProgress"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive
                              ? completed_dark_img
                              : completed_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Completed
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalMilestones"]["completed"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={
                            isDarkModeActive ? dispute_dark_img : dispute_img
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            In Dispute
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalMilestones"]["inDispute"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-6">
                      <div className="symbol symbol-45px w-40px me-5">
                        <img
                          src={isDarkModeActive ? others_dark_img : others_img}
                        />
                      </div>

                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a
                            href="#"
                            className="fs-5 text-gray-800  fw-bolder"
                          >
                            Others
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-5 text-gray-800 pe-1">
                            {dashboard["totalMilestones"]["others"]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card card-flush mb-5">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <div className="d-flex align-items-center me-5 me-xl-13">
                      <div className="symbol symbol-circle me-3">
                        <img
                          src={isDarkModeActive ? earn_dark_img : earn_img}
                        />
                      </div>
                      <div className="m-0">
                        <span className="pt-1 fw-bold fs-6">
                          Estimated Earning
                        </span>
                        <div className="d-flex align-items-center">
                          <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                            {dashboard["totalEarnings"]["totalEstimated"]}
                          </span>

                          <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                            ◎
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-5">
                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      Total Earned
                    </div>

                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalEarnings"]["totalEarned"]}◎
                      </span>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-3"></div>

                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      In Escrow
                    </div>

                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalEarnings"]["inEscrow"]}◎
                      </span>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-3"></div>

                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      Pending
                    </div>
                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalEarnings"]["pending"]}◎
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-flush">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <div className="d-flex align-items-center me-5 me-xl-13">
                      <div className="symbol symbol-circle me-3">
                        <img
                          src={isDarkModeActive ? spend_dark_img : spend_img}
                        />
                      </div>
                      <div className="m-0">
                        <span className="pt-1 fw-bold fs-6">
                          Estimated Spending
                        </span>
                        <div className="d-flex align-items-center">
                          <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                            {dashboard["totalSpends"]["totalEstimated"]}
                          </span>
                          <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                            ◎
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-5">
                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      Total Spending
                    </div>

                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalSpends"]["totalSpend"]}◎
                      </span>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-3"></div>

                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      In Escrow
                    </div>

                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalSpends"]["inEscrow"]}◎
                      </span>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-3"></div>

                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-bold fs-6 me-2">
                      Pending
                    </div>
                    <div className="d-flex align-items-senter">
                      <span className="text-gray-900 fw-boldest fs-6">
                        {dashboard["totalSpends"]["pending"]}◎
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-5 g-xl-10 mb-xl-10">
            <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
              <div
                className="d-flex flex-wrap flex-stack mb-6"
                data-select2-id="select2-data-188-nq9o"
              >
                <h3 className="fw-bolder my-2 d-sub-heading">
                  Project Details
                </h3>

                <div className="d-flex flex-wrap my-2">
                  <Link
                    className="btn-view-all"
                    to={{ pathname: "/my-project" }}
                    state={{ from: "my-project" }}
                  >
                    View All
                  </Link>
                </div>
              </div>
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                {/* <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                      Project Details
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <Link
                      className="btn btn-sm btn-view-all"
                      to={{ pathname: "/my-project" }}
                      state={{ from: "my-project" }}
                    >
                      View All Projects
                    </Link>
                  </div>
                </div> */}

                <div className="card-body py-3">
                  <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bolder text-muted">
                          <th className="min-w-150px">Project Name </th>
                          <th className="min-w-150px">Status</th>
                          <th className="min-w-100px text-end">Actions</th>
                        </tr>
                      </thead>
                      {dashboard &&
                      "recentProjects" in dashboard &&
                      dashboard["recentProjects"].length > 0 ? (
                        <tbody>
                          {dashboard["recentProjects"].map(
                            (recentProjects, index) => (
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    onClick={call_project_details(
                                      recentProjects.projectId
                                    )}
                                    className="text-dark fw-bolder text-hover-primary d-block fs-6"
                                  >
                                    {recentProjects.projectTitle}
                                  </a>
                                </td>
                                <td className="text-end">
                                  <div className="d-flex flex-column w-100 me-2">
                                    <div className="d-flex flex-stack mb-2">
                                      <span className="me-2 fs-7 fw-bold d-project-status">
                                        {recentProjects.status}
                                      </span>
                                    </div>
                                    {/* <div className="progress h-6px w-100">
										<div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }}></div>
									</div> */}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-end flex-shrink-0">
                                    <a
                                      href="#"
                                      onClick={call_project_details(
                                        recentProjects.projectId
                                      )}
                                      className="btn btn-view-project btn-sm px-4 me-2"
                                    >
                                      <i
                                        style={{ fontSize: "1.3rem" }}
                                        className="bi bi-eye"
                                      ></i>
                                      View
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
              <div
                className="d-flex flex-wrap flex-stack mb-6"
                data-select2-id="select2-data-188-nq9o"
              >
                <h3 className="fw-bolder my-2 d-sub-heading">
                  Active Milestones
                </h3>
              </div>
              <div className="h-xl-100">
                {/* <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">
                      Active Milestones
                    </span>
                    <span className="text-muted mt-1 fw-bold fs-7"></span>
                  </h3>
                </div> */}
                {dashboard && dashboard["activeMilestones"].length > 0 ? (
                  <div className="">
                    {dashboard["activeMilestones"].map((milestone) => (
                      <div className="d-a-m-card">
                        <div className="d-flex flex-stack">
                          <div className="symbol symbol-40px me-4">
                            {milestone.mileStoneTitle != null && (
                              <div className="symbol-label fs-2 fw-bold bg-info">
                                {milestone.mileStoneTitle[0]}
                              </div>
                            )}
                          </div>

                          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                            <div className="flex-grow-1 me-2">
                              <a
                                href="#"
                                onClick={call_project_details(
                                  milestone.projectId
                                )}
                                className="text-gray-800 text-hover-primary fs-6 fw-bolder"
                              >
                                {milestone.mileStoneTitle}
                              </a>
                              <span className="text-muted fw-bold d-block fs-7">
                                {milestone.projectTitle}
                              </span>
                            </div>

                            <a
                              href="#"
                              onClick={call_project_details(
                                milestone.projectId
                              )}
                              className=""
                            >
                              <span className="icon">
                                <i className="bi bi-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>

                        {/* <div className="separator separator-dashed my-4"></div> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="card-body pt-6">
                    <div className="d-flex flex-stack text-center">
                      <h5 className="text-primary">No Active Milestones</h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row g-5 g-xl-8">
            <div className="col-xxl-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};
