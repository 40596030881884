import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./login.css";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import logo from "../images/ICON_V1.png";
import login_icon from "../images/vault_x_banner_done.png";
import twitter from "../images/twitter.png";
import discord from "../images/discord.png";
import { Modal } from "react-bootstrap";
// import styled from "styled-components";

// const ConnectButton = styled(WalletMultiButton)`&&{
//     display: inline-block;
//     position: relative;
//     background: rgb(0 0 0 / 12%);
//     border: solid 2px #fff;
//     border-radius: 21px;
//     font-size: 12px;
//     font-family: 'My SoraExtra Bold font';
//     font-weight: bold;
//     text-transform: uppercase;
//     color: white;
//     //padding: 20px 40px;
//     // border: none;
//     // border-radius: 50px;
//     //margin: 10px;
//     cursor: pointer;
//     transition: transform 0.1s, box-shadow 0.1s;
//     &:hover{
//       text-shadow: #f7a828 1px 0 10px;
//       background-color: rgb(0 0 0 / 12%);
//       color: white;
//   }

//   }
//   `;

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

export const Login: FC = () => {
  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();
  const [showDisclaimer, setshowDisclaimer] = useState(false);

  // const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  return (
    <>
      <div
        className="d-flex flex-column flex-lg-row-auto w-xl-400px positon-xl-relative"
        style={{ backgroundColor: "#1e4395" }}
      >
        <div
          className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-400px scroll-y login-left-container"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
            <a href="#" className="py-9 mb-5">
              <img alt="Logo" src={logo} className="login-page-logo" />
            </a>

            <h1
              className="pb-5 pb-md-10"
              style={{ color: "#fff", fontSize: "30px"  }}
            >
              Welcome to SOLFUL
            </h1>

            <p className="" style={{ color: "#ffffffcc", fontSize: "16px" }}>
            Vault-X and/or Solful keeps your funds secure while dealing with anonymous
              people. Our Protection protocol for Web 3.0 safeguards the
              interests of both the buyer and the seller through a decentralized
              and autonomous vault contract.
            </p>

            <div className="gap-5 mt-5 mb-2 m-auto">
              <a
                href="https://twitter.com/TheVaultDAO"
                target={"blank"}
                className=""
              >
                <img src={twitter} className="w-50px" alt="" />
              </a>
              <a href="https://discord.gg/CaSVfkCbvc" target={"blank"}>
                <img src={discord} className="w-50px" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="d-flex flex-center flex-column flex-column-fluid login-container">
          <div className="w-lg-800px p-10 p-lg-15 mx-auto">
            <div
              className="login-gif-animation d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
              style={{}}
            >
              <img src={login_icon} />
            </div>
            <form className="form w-100">
              <div className="text-center mb-10">
                <WalletMultiButton className="" />
                {/* <ConnectButton>Connect Wallet</ConnectButton> */}
              </div>
              {/* <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                            <label className="form-check form-check-custom form-check-solid form-check-inline term-and-contion-label">
                                <input className="form-check-input" type="checkbox" name="toc" value="1" />
                                <span className="form-check-label fw-bold text-gray-700 fs-6">I Agree
                                    <a href="#" className="ms-1 text-primary">Terms and conditions</a>.</span>
                            </label>
                            <div className="fv-plugins-message-container invalid-feedback"></div>
                        </div> */}
            </form>
          </div>
        </div>
        <div className="footer d-flex flex-lg-column" id="login_footer">
          <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
            <div className="text-dark order-2 order-md-1"></div>

            <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
              <li className="menu-item">
                <a
                  className="menu-link px-2"
                  onClick={() => setshowDisclaimer(true)}
                >
                  By Signing up with your wallet, you are agreeing to our{" "}
                  <span className="disclaimer-bold"> Disclaimer</span>
                </a>
              </li>
            </ul>
          </div>
          <Modal
            show={showDisclaimer}
            onHide={() => setshowDisclaimer(false)}

            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="disclaimer-justified">
                You own or control all rights in and to any content including,
                without limitation, all data you input into the Site or
                Services. You understand and acknowledge that you are
                responsible for any data you submit to the Site or Services, and
                you, not Vault-X and/or Solful DAO, have full responsibility for such content,
                including its legality, reliability, accuracy, and
                appropriateness.
              </p>
              <p className="disclaimer-justified">
                You understand and agree that the value of digital assets can be
                volatile, and we are not in any way responsible or liable for
                any losses you may incur by using or transferring digital assets
                in connection with our Services. We do not provide investment
                advice and any content contained on the Site should not be
                considered as a substitute for tailored investment advice. The
                contents of our Site and the Services should not be used as a
                basis for making investment decisions
              </p>
              <p className="disclaimer-justified">
              Vault-X and/or Solful maintains a zero-tolerance policy regarding all forms of
                discrimination, harassment, or abuse. Users may not refuse to
                provide or accept services based on a person’s race, religion,
                national origin, disability, sexual orientation, sex, marital
                status, gender identity, age or any other characteristic
                protected under applicable federal or state law.
              </p>
            </Modal.Body>
          </Modal>
        </div>
        <div className="poweredby">
          <a href="http://thevault-x.com/" target="_blank">
            Powered by Vault-x
          </a>
        </div>
      </div>
    </>
  );
};
